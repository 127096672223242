import React, { useEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"

import useIntersectionObserver from "../../hooks/useIntersectionObserver"

import AnimatedSection from "./AnimatedSection"
import HealMeVerifiedBadge from "./HealMeVerifiedBadge"
import Review from "./Review"
import ReviewStars from "./ReviewStars"
import SectionTitle from "./SectionTitle"
import SeeMore from "./SeeMore"

const ReviewsSectionVariationOne = ({ practice, theme, className, backgroundColor, color, starsClassName }) => {
  backgroundColor ||= theme.colorBgLight
  color ||= theme.colorTextDark

  const isMobile = window.innerWidth < 768
  const defaultReviewsShown = isMobile ? 4 : 10
  const reviews = practice.reviews.filter((review) => review.review)
  const [expanded, setExpanded] = useState(false)

  const [isVisible, containerRef] = useIntersectionObserver()

  const reviewsContainerRef = useRef(null)

  useEffect(() => {
    if (reviewsContainerRef.current) {
      reviewsContainerRef.current.style.maxHeight = expanded ? `${reviewsContainerRef.current.scrollHeight}px` : "0px"
    }
  }, [expanded, reviews])

  return (
    <section id="reviews" className={twMerge("pt-24 md:px-5 md:pt-16", className)} style={{ backgroundColor, color }}>
      <div className="container mx-auto" ref={containerRef}>
        <div className="hidden md:block">
          <img src={practice.reviewsSectionImageUrl} alt="Practice" className="h-60 w-full object-cover" />
        </div>
        <SectionTitle className="mb-4 md:mb-0 md:mt-8 md:text-center" theme={theme}>
          My Reviews
        </SectionTitle>
        <div className={twMerge("flex items-center justify-center gap-4", starsClassName)}>
          <ReviewStars rating={practice.averageRating} starColor={theme.colorReviewStars} />
          <span>{practice.reviews.length} reviews</span>
        </div>
        <div className="my-8 hidden justify-center md:flex">
          <HealMeVerifiedBadge theme={theme} backgroundColor={backgroundColor} color={color} />
        </div>
        <AnimatedSection className="flex gap-16 md:w-full" style={{ color }}>
          <div className="flex w-1/2 flex-col gap-10 md:w-full md:gap-8">
            <div className="mt-10 flex justify-start md:hidden">
              <HealMeVerifiedBadge theme={theme} backgroundColor={backgroundColor} color={color} />
            </div>
            {reviews.slice(0, 4).map((review) => (
              <Review
                key={`review-${review.id}`}
                review={review}
                practice={practice}
                starColor={theme.colorReviewStars}
              />
            ))}
          </div>
          <div className="w-1/2 md:hidden">
            <img
              src={practice.reviewsSectionImageUrl}
              alt={practice.user.name}
              className="h-full w-full object-cover"
            />
          </div>
        </AnimatedSection>
        <div
          ref={containerRef}
          className={`mt-16 grid grid-cols-2 gap-16 transition-all duration-1000 md:mt-8 md:grid-cols-1 md:gap-8 ${
            isVisible ? "translate-y-0 opacity-100" : "-translate-y-24 opacity-0"
          }`}>
          {reviews.slice(4, defaultReviewsShown).map((review) => (
            <Review
              key={`review-${review.id}`}
              review={review}
              practice={practice}
              starColor={theme.colorReviewStars}
            />
          ))}
        </div>
        {expanded && <div className="h-10 md:h-8" />}
        <div
          ref={reviewsContainerRef}
          className="grid grid-cols-2 gap-16 overflow-hidden transition-all duration-1000 lg:grid-cols-1 md:gap-8"
          style={{ maxHeight: 0 }}>
          {reviews.slice(defaultReviewsShown).map((review) => (
            <Review
              key={`review-${review.id}`}
              review={review}
              practice={practice}
              starColor={theme.colorReviewStars}
            />
          ))}
        </div>
        {reviews.length > defaultReviewsShown && (
          <SeeMore
            className="pt-10 text-opacity-50"
            buttonClassName="hover:text-[var(--hover-color)]"
            buttonStyle={{ color, "--hover-color": theme.colorAccent }}
            buttonText="See more reviews"
            onClick={() => {
              if (expanded) document.getElementById("reviews").scrollIntoView({ behavior: "smooth" })
              setExpanded(!expanded)
            }}
          />
        )}
      </div>
    </section>
  )
}

export default ReviewsSectionVariationOne
