import { CubeIcon, NewspaperIcon, SparklesIcon } from "@heroicons/react/24/outline"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid"
import React, { useState } from "react"

import { ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"

import ContentTab from "./ContentTab"
import StyleTab from "./StyleTab"
import ThemeTab from "./ThemeTab"
import { useWebsiteBuilder, WebsiteBuilderProvider } from "./WebsiteBuilderContext"
import WebsitePreviewView from "./WebsitePreviewView"

import "../../components/shared/wysiwyg.sass"

export const DEFAULT_HIGHLIGHT_CLASSES = "rounded border-2 border-teal shadow-diffuse bg-teal/10"

const AnimatedSection = ({ section, isActive, toggleSection }) => (
  <div className="overflow-hidden">
    <button
      className="inline-flex h-14 w-full items-center justify-between border-b border-gray bg-gray-ultralight px-8 py-4 transition-colors"
      onClick={() => toggleSection(section)}>
      <span className="flex items-center gap-3">
        {section === "Theme" && <CubeIcon className="h-6 w-6" />}
        {section === "Style" && <SparklesIcon className="h-6 w-6" />}
        {section === "Content" && <NewspaperIcon className="h-6 w-6" />}
        <span className="text-lg font-bold leading-snug">{section}</span>
      </span>
      {isActive ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
    </button>
    <div
      className={`transition-all duration-300 ease-in-out ${
        isActive ? "max-h-[5000px] opacity-100" : "max-h-0 opacity-0"
      }`}>
      <div className="px-8 py-4">
        {section === "Theme" && <ThemeTab />}
        {section === "Style" && <StyleTab />}
        {section === "Content" && <ContentTab />}
      </div>
    </div>
  </div>
)

const WebsiteBuilder = () => {
  const { setIsPreviewing, setOpenField, practice } = useWebsiteBuilder()
  const [activeSection, setActiveSection] = useState(practice.showContentTab ? "Content" : "Theme")

  const sections = ["Theme", "Style", "Content"]

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section)
    setIsPreviewing(false)
    setOpenField(null)
  }

  return (
    <div className="">
      {sections.map((section) => (
        <AnimatedSection
          key={section}
          section={section}
          isActive={activeSection === section}
          toggleSection={toggleSection}
        />
      ))}
    </div>
  )
}

export default function WebsiteBuilderPage(props) {
  return (
    <ToastProvider>
      <GraphQLProvider>
        <WebsiteBuilderProvider {...props}>
          <WebsitePreviewView>
            <WebsiteBuilder />
          </WebsitePreviewView>
        </WebsiteBuilderProvider>
      </GraphQLProvider>
    </ToastProvider>
  )
}
