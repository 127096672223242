import { WebAppBookNow } from "@heal-me/healme-widget"
import React from "react"
import { twMerge } from "tailwind-merge"

import SectionTitle from "../ProProfileShared/SectionTitle"
import ServiceCard from "../ProProfileShared/ServiceCard"

const ServicesSection = ({ practice, theme, viewingInBuilder }) => {
  const services = practice.services.slice(0, 3)

  const imageUrl = practice.servicesSectionImageUrl

  return (
    <section id="services" style={{ backgroundColor: theme.colorBgLight }} className="w-full">
      <div className="flex flex-col min_md:flex-row">
        <div className="hidden md:flex md:w-full">
          <img src={imageUrl} alt="Services" className="h-full w-full object-cover" />
        </div>
        <div className="py-24 md:pb-20 md:pt-10 min_md:w-1/2 min_md:pl-32 min_md:pr-16">
          <SectionTitle theme={theme} color={theme.colorTextDark} className="text-left md:text-center">
            My Services
          </SectionTitle>
          <div className="my-10">
            <div className="space-y-10">
              {services.map((service) => (
                <ServiceCard
                  key={service.id}
                  service={service}
                  practice={practice}
                  theme={theme}
                  viewingInBuilder={viewingInBuilder}
                  cardBackgroundColor={theme.colorGreyLightest}
                />
              ))}
            </div>
          </div>
          <div className="text-left md:text-center">
            <WebAppBookNow
              practiceId={practice.id}
              buttonCopy="See all services"
              buttonClasses={twMerge(
                "border px-10 py-3 hover:bg-[var(--hover-color)] hover:text-white",
                theme.buttonStyle === "rounded" ? "rounded-full" : "rounded",
                viewingInBuilder ? "pointer-events-none" : ""
              )}
              style={{
                "--hover-color": theme.colorAccent,
                borderColor: theme.colorAccent,
                color: theme.colorAccent
              }}
            />
          </div>
        </div>
        <div className="md:hidden min_md:w-1/2">
          <img src={imageUrl} alt="Services" className="h-full w-full object-cover" />
        </div>
      </div>
    </section>
  )
}

export default ServicesSection
