import { WebAppBookNow } from "@heal-me/healme-widget"
import React from "react"
import { twMerge } from "tailwind-merge"

import { formatPrice, truncate } from "../../utils/utils"
import NextAvailableSlot from "../ProProfile/NextAvailableSlot"

import CardTitle from "./CardTitle"

const ServiceCard = ({ service, practice, theme, cardBackgroundColor, viewingInBuilder }) => (
  <>
    {service.id === "image" ? (
      <img src={service.url} alt="Practice" className="max-h-[368px] w-full object-cover md:hidden" />
    ) : (
      <div
        className="p-8 text-left md:p-6"
        style={{ backgroundColor: cardBackgroundColor, color: theme.colorTextDark }}>
        <CardTitle className="mb-4" style={{ fontFamily: theme.fontHeading, fontWeight: theme.fontHeadingWeight }}>
          {service.name}
        </CardTitle>
        <div className="mb-2 min-h-[72px]">
          <span
            className="wysiwyg-content"
            dangerouslySetInnerHTML={{
              __html: truncate(service.description, 160).replace(/<\/?(p|h2|h3|h4|ul|ol|li)[^>]*>/g, " ")
            }}
          />
          {service.description.length > 160 && (
            <WebAppBookNow
              practiceId={practice.id}
              serviceId={service.id}
              buttonCopy="See more"
              style={{ fontWeight: theme.fontParagraphBoldWeight }}
              buttonClasses={twMerge("ml-1 underline", viewingInBuilder ? "pointer-events-none" : "")}
              openServiceDetail={true}
            />
          )}
        </div>
        <p className="leading-loose">
          {service.amountCents === 0 ? "Free" : formatPrice(service.amountCents)} ·{" "}
          {service.package ? ` ${service.numberOfSessions} sessions · ` : ""}
          {service.timeLength} minutes{service.package ? " each" : ""}
        </p>
        <div className="mt-8 flex items-center gap-2 sm:flex-col sm:items-start sm:gap-4">
          <WebAppBookNow
            practiceId={practice.id}
            serviceId={service.id}
            buttonCopy="Book now"
            buttonClasses={twMerge(
              "rounded border bg-transparent px-6 py-3 transition duration-150 ease-out hover:bg-[var(--hover-bg)] hover:text-[var(--hover-color)] sm:w-full",
              viewingInBuilder ? "pointer-events-none" : ""
            )}
            style={{
              color: theme.colorAccent,
              borderColor: theme.colorAccent,
              "--hover-color": theme.colorBgLight,
              "--hover-bg": theme.colorAccent
            }}
          />
          {!service.package && !viewingInBuilder && (
            <div className="flex gap-2">
              <span>→</span>
              <NextAvailableSlot service={service} />
            </div>
          )}
        </div>
      </div>
    )}
  </>
)

export default ServiceCard
